<template>
  <div class="content">
    <ApplicationNannyInputAddress
      v-model:address="applicationNanny.details.location.address"
      v-model:coordinates-lat-lng="coordsLatLng"
      @update:is-valid="isValid = $event"
      :zoom="16"
    />

    <ButtonPrimaryNext @click="handleButtonNext()" :isDisabled="!isValid" />
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { onMounted } from "vue";
import { useUserProfileStore } from "@/stores/userProfile";
import { useApplicationNannyStore } from "@/stores/applicationNanny";
import { useRouter } from "vue-router";

import { ROUTE_APPLICATION_NANNY_CREATE_AUPAIR_NAME } from "@/router/constants";

import ApplicationNannyInputAddress from "@/components/Application/Nanny/ApplicationNannyInputAddress.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";
import useCoordsReversed from "@/composables/useCoordsReversed";
import useGoogleMapsGeocoder from "@/composables/useGoogleMapsGeocoder";
import useCommonDictionary from "@/composables/useCommonDictionary";

export default {
  components: {
    ApplicationNannyInputAddress,
    ButtonPrimaryNext,
  },

  setup() {
    // Stores
    const userProfile = useUserProfileStore();
    const applicationNanny = useApplicationNannyStore();

    // Hooks
    const router = useRouter();
    const { DICTIONARY_COMMON } = useCommonDictionary();
    const { coordsLatLngToCityName } = useGoogleMapsGeocoder();
    const { coordsReversed: coordsLatLng } = useCoordsReversed({
      coordsRef: computed(
        () => applicationNanny.details.location.point.coordinates
      ),
      setCoords: (newCoords) => {
        applicationNanny.details.location.point.coordinates = newCoords;
      },
    });

    // Vars
    const isValid = ref(null);

    // TODO: make default coordinates dynamic. Currently set coords of the Wroclaw city center
    const DEFAULT_COORDINATES = [17.0385376, 51.1078852]; // in LngLat !!! mongo style

    const setInitialLocationPoint = () => {
      if (applicationNanny.details.location.point.coordinates.length > 0) {
        return;
      }

      let locationPoint = userProfile.zipLocationPoint;

      if (!locationPoint) {
        // Set default coordinates
        locationPoint = {
          type: "Point",
          coordinates: DEFAULT_COORDINATES,
        };
      }

      applicationNanny.details.location.point = locationPoint;
    };

    const getUserCity = async () => {
      console.log("GET_USER_CITY_NAME");
      let cityName = null;

      try {
        cityName = await coordsLatLngToCityName(coordsLatLng.value);
      } catch (error) {
        console.log(error, "ERROR: -> RETURN_DEFAULT_CITY_FROM_MARKET");

        // Set default city from cityMarket
        const userCityMarketSlug = applicationNanny.details.location.cityMarket;
        cityName = DICTIONARY_COMMON[userCityMarketSlug];
      }

      return cityName;
    };

    const setUserCity = (cityName) => {
      console.log(`SET_CITY_TO_APPLICATION_LOCATION: ${cityName}`);
      applicationNanny.details.location.city = cityName;
    };

    const handleButtonNext = async () => {
      const userCity = await getUserCity();
      setUserCity(userCity);
      router.push({ name: ROUTE_APPLICATION_NANNY_CREATE_AUPAIR_NAME });
    };

    onMounted(() => {
      setInitialLocationPoint();
    });

    return {
      applicationNanny,
      isValid,
      handleButtonNext,
      coordsLatLng,
    };
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 40px;
}
.wrapper-geo {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 25px;
}

@media (max-width: 1200px) {
}
</style>
